import type * as React from 'react';
import { cn } from '~/utils/cn';
import { HeadingM, textVariants } from './Typography';

const Card = ({ ref, className, ...props }: React.ComponentPropsWithRef<'div'>) => (
  <div
    ref={ref}
    className={cn(
      'rounded-lg bg-card shadow-[0px_4px_0px_0px_#1930350A] md:shadow-[0px_8px_0px_0px_#1930350A]',
      className,
    )}
    {...props}
  />
);
Card.displayName = 'Card';

const CardHeader = ({ ref, className, ...props }: React.ComponentPropsWithRef<'div'>) => (
  <div
    ref={ref}
    className={cn('flex flex-col gap-y-6 p-6 md:p-10 md:pb-6', className)}
    {...props}
  />
);
CardHeader.displayName = 'CardHeader';

const CardTitle = ({ ref, ...props }: React.ComponentProps<typeof HeadingM>) => (
  <HeadingM ref={ref} {...props} />
);
CardTitle.displayName = 'CardTitle';

const CardDescription = ({ ref, className, ...props }: React.ComponentPropsWithRef<'p'>) => (
  <p
    ref={ref}
    className={cn(textVariants({ size: 'xl' }), 'font-medium text-subdued-foreground', className)}
    {...props}
  />
);
CardDescription.displayName = 'CardDescription';

const CardContent = ({ ref, className, ...props }: React.ComponentPropsWithRef<'div'>) => (
  <div ref={ref} className={cn('p-6 pt-0 md:px-10', className)} {...props} />
);
CardContent.displayName = 'CardContent';

const CardFooter = ({ ref, className, ...props }: React.ComponentPropsWithRef<'div'>) => (
  <div
    ref={ref}
    className={cn('flex items-center p-6 pt-0 md:px-10 md:pb-10', className)}
    {...props}
  />
);
CardFooter.displayName = 'CardFooter';

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent };
