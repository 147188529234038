import { useFetchers } from '@remix-run/react';
import * as React from 'react';
import { UNSAFE_DataRouterStateContext as DataRouterStateContext } from 'react-router-dom';
import invariant from 'tiny-invariant';

export function useRouteActionData() {
  const { actionData } = useDataRouterStateContext();

  return actionData;
}

function useDataRouterStateContext() {
  const context = React.use(DataRouterStateContext);
  invariant(
    context,
    'You must render this element inside a <DataRouterStateContext.Provider> element',
  );
  return context;
}

const ARRAY_EMPTY: (
  | { error: Error; type: 'error'; id: string; description?: string }
  | { message: string; type: 'message'; id: string; description?: string }
  | { message: string; type: 'success'; id: string; description?: string }
)[] = [];

export function useRouteActionToasts(): typeof ARRAY_EMPTY {
  const actionsData = useRouteActionData();
  const fetchersData = useFetchers();
  const actions = React.useMemo(() => {
    return actionsData
      ? Object.values(actionsData)
          .filter((actionData) => actionData?.__data?.type)
          .map((a) => a.__data)
      : ARRAY_EMPTY;
  }, [actionsData]);

  const fetchers = React.useMemo(() => {
    return fetchersData
      ? fetchersData.filter((fetcher) => fetcher.data?.__data?.type).map((a) => a.data.__data)
      : ARRAY_EMPTY;
  }, [fetchersData]);

  return React.useMemo(() => {
    return [...actions, ...fetchers];
  }, [actions, fetchers]);
}
